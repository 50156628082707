import React, { useEffect, useState } from "react";
import { Drawer, Box, useTheme, useMediaQuery, Toolbar } from "@mui/material";
import { makeStyles } from "@mui/styles";

import PerfectScrollbar from "react-perfect-scrollbar";

import { useDispatch, useSelector } from "react-redux";
import { setSiderbarOpened } from "../../../redux/app/appSlice";

import { privateApiPOST } from "../../../components/PrivateRoute";
import Api from "../../../components/Api";
import {
  setChannelSelected,
  setIsChannelCreating,
  setChannelInfo,
} from "../../../redux/query/querySlice";
import FeatureItem from "./FeatureItem";

const drawerWidth = 280;
const useStyles = makeStyles((theme) => ({
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
    backgroundColor: theme.palette.grey["800"],
    color: theme.palette.common.white,
    boxSizing: "border-box",
    borderRight: "none",
  },
  sidebarWrapper: {
    paddingTop: "20px",
    paddingBottom: "20px",
    "& ul": {
      listStyle: "none",
      margin: 0,
    },
    "& .MuiButtonBase-root": {
      fontFamily: theme.typography.fontFamily,
      display: "flex",
      width: "100%",
      justifyContent: "flex-start",
      color: "#d3d3d3",
      letterSpacing: "0.4px",
    },
  },
  sidebarList: {
    padding: 0,
    "& >li": {
      paddingBottom: "3px",
      paddingTop: "3px",
      "& >.MuiButtonBase-root": {
        padding: "8px 15px",
        fontSize: "15px",
        lineHeight: "22px",
        fontWeight: 500,
        "&:hover": {
          backgroundColor: "#3a3a3a",
        },
      },
    },
  },
  listSelected: {
    backgroundColor: "#3a3a3a !important",
    color: "inherit !important",
  },
  chilItemSelected: {
    color: "#ffb442 !important",
  },
  childList: {
    padding: "0 0 5px",
    "& >li": {
      paddingTop: "1px",
      paddingBottom: "1px",
      position: "relative",
      "& >.MuiButtonBase-root": {
        padding: "6px 30px",
        fontSize: "14px",
        lineHeight: "20px",
        color: "#a7a7a7",
        "&:hover": {
          color: "#e1e1e1",
        },
      },
    },
  },
  sidebarTitle: {
    fontWeight: 500,
    marginTop: "10px",
    letterSpacing: "0.5px",
    fontSize: "15px",
    lineHeight: "23px",
    color: "#9b9b9b",
  },
}));

const Sidebar = ({ window }) => {
  const { isSidebarOpened, menuAccessList, menuSelected } = useSelector(
    (state) => state.app
  );

  const { channelAccessList } = useSelector((state) => state.query);
  const dispatch = useDispatch();

  const theme = useTheme();
  const matchUpMd = useMediaQuery(theme.breakpoints.up("md"));
  const matchDownMd = useMediaQuery(theme.breakpoints.down("md"));

  const handleDrawerToggle = () =>
    dispatch(setSiderbarOpened(!isSidebarOpened));

  const container =
    window !== undefined ? () => window().document.body : undefined;

  useEffect(() => {
    dispatch(setSiderbarOpened(!matchDownMd));
  }, [matchDownMd]);

  // FETCH - Channel info
  const handleFetchChannelInfo = (id) => {
    dispatch(setIsChannelCreating(true));
    privateApiPOST(Api.channelInfo, { bot_query_mode: id })
      .then((res) => {
        const { data, status } = res;
        // console.log("Create channel data ---> ", data);
        if (status === 200) {
          dispatch(setChannelInfo({ data: data?.data, id }));
          dispatch(setIsChannelCreating(false));
        }
      })
      .catch((error) => {
        console.log("Create channel error ---> ", error);
        dispatch(setIsChannelCreating(false));
      });
  };

  // SIDEBAR MENU CHANGE - Create channel(session id) if 'is_query_mode' is true otherwise redirect to page
  useEffect(() => {
    const { id, is_query_mode } = menuSelected;
    const activeChannel = channelAccessList.filter((item) => item.id === id);

    if (id != "" && !activeChannel[0]?.channel_session_id && is_query_mode) {
      dispatch(setChannelSelected({}));
      handleFetchChannelInfo(id);
    } else {
      dispatch(setChannelSelected({ ...activeChannel[0] }));
    }
  }, [menuSelected]);

  const classes = useStyles();
  return (
    <Box component="nav">
      <Drawer
        container={container}
        variant={matchUpMd ? "persistent" : "temporary"}
        open={isSidebarOpened}
        onClose={handleDrawerToggle}
        ModalProps={{
          keepMounted: true, // Better open performance on mobile.
        }}
        classes={{
          paper: classes.drawerPaper,
        }}
      >
        <Toolbar />
        <PerfectScrollbar>
          <Box className={classes.sidebarWrapper}>
            <ul className={classes.sidebarList}>
              {menuAccessList.length &&
                menuAccessList.map((menu, index) => {
                  return (
                    <li key={index}>
                      <FeatureItem itemObj={menu} />
                    </li>
                  );
                })}
            </ul>
          </Box>
        </PerfectScrollbar>
      </Drawer>
    </Box>
  );
};

export default Sidebar;
